@import "tailwindcss/base";

html {
  font-family: "Karla", sans-serif;
}
h1 {
  font-family: "Oswald", sans-serif;
}

body {
  color: #303030;
}

@import "tailwindcss/components";
@import "tailwindcss/utilities";

/*
html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
} */

.bg-transparent {
  background-color: transparent;
}

.hover\:bg-transparent:hover {
  background-color: transparent;
}
/*
.banner-area {
  height: calc(100vh - theme("spacing.24"));
} */

.button-wrapper {
  /* background-image: url(button_base.png); */
  background-position: center;
  /* width: 100px; */
  /* height: 100px; */
}

.regular-shadow {
  text-shadow: 2.5px 2.5px 0px rgba(0, 0, 0, 0.25);
  line-height: 46px;
}

.texture-shadow {
  text-shadow: -0.03em 0.03em 0em rgba(0, 0, 0, 0.25);
  line-height: 1.1em;
}

.white-patch {
  background-image: url(../images/patterns/white-patch.png);
}

body.ReactModal__Body--open {
  height: 100vh;
  overflow-y: hidden;
}

.ns-primary-button {
  position: relative;
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.0816761);
}

.ns-primary-button:hover {
  /* transform: translateY(-50px) !important; */
  box-shadow: 2px 8px 14px rgba(0, 0, 0, 0.2);
  transition: all 0.2s;
}

/*
https://thiyagaraj-t.medium.com/checkered-shadow-effect-in-css-8063c48e87fa
*/

.texture {
  color: white;
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 2;
}

.texture:after {
  z-index: -1;
  left: -4px;
  top: 4px;
  position: absolute;
  content: attr(data-text);
  color: transparent;
  background-image: linear-gradient(45deg, #6b6b6b 25%, transparent 25%),
    linear-gradient(-45deg, #6b6b6b 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #6b6b6b 75%),
    linear-gradient(-45deg, transparent 75%, #6b6b6b 75%);
  background-size: 4px 4px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
  background-clip: text;
  -webkit-background-clip: text;
}

.ticket-no {
  writing-mode: tb;
  transform: rotate(-180deg);
}
